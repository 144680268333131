<template>
  <div class="pricing">
    <div class="popular" v-if="false">
      MOST POPULAR
    </div>
    <div class="pricing-header">
      <span class="d-block title">{{ subscriptionData.name }} </span>
      <div class="price my-5">
        <span class="unit">$</span>
        <span class="number">{{ subscriptionData.config.platformLattexFee.sub }}</span>
        <span class="time">/Month</span>
      </div>
    </div>
    <v-btn color="primary" width="100%" :height="40" class="mb-5 mt-4" @click="handleConfirmPlan"
      >Choose this plan</v-btn
    >
    <v-divider class="mb-6"></v-divider>
    <ul>
      <li>
        <v-icon>mdi-check primary--text</v-icon>
        <span>Payment Fee {{ subscriptionData.config.paymentGatewayFee }}% </span>
      </li>
      <li>
        <v-icon>mdi-check primary--text</v-icon>
        <span>Processing Fee {{ subscriptionData.config.processingFee }}%</span>
        <span></span>
      </li>
      <li>
        <v-icon>mdi-check primary--text</v-icon>
        <span>Proxy Fee {{ subscriptionData.config.proxySubscription }}%</span>
        <span></span>
      </li>
      <li>
        <v-icon>mdi-check primary--text</v-icon>
        <span>Platform Fee {{ subscriptionData.config.platformLattexFee.rev }}%</span>
      </li>
      <li v-if="subscriptionData.config.hold">
        <v-icon>mdi-check primary--text</v-icon>
        <span>Hold {{ subscriptionData.config.hold.percent }}% in {{ subscriptionData.config.hold.date }} days</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['subscriptionData'],
  methods: {
    handleConfirmPlan() {
      this.$store.commit('setSubscriptionSelected', this.subscriptionData);
      this.$emit('choosePlan');
    },
  },
};
</script>

<style lang="scss" scoped>
.pricing {
  text-align: center;
  border: 1px solid #dbdfe1;
  border-radius: 14px;
  padding: 24px 16px;
  background-image: linear-gradient(-180deg, #fff, rgba(245, 246, 247, 0.5));
  position: relative;
  .popular {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 12px;
    border: 1px solid #0061af;
    padding: 4px 12px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
  }
  &-header {
    p {
      font-size: 12px;
      color: #6e7a81;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      height: 55px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .price {
      color: #363e43;
      display: flex;
      align-items: center;
      justify-content: center;

      .unit {
        font-size: 32px;
        align-self: flex-start;
        margin-right: 4px;
      }
      .number {
        font-size: 60px;
        font-weight: 700;
        margin-top: 24px;
        line-height: 0.75;
        align-self: center;
        margin-right: 4px;
      }
      .time {
        font-size: 16px;
        line-height: 24px;
        align-self: flex-end;
      }
    }
  }
  ul {
    padding-left: 4px;
    list-style: none;
    li {
      text-align: left;
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: 8px;
      i {
        margin-right: 8px;
      }
    }
  }
}
</style>
